import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const styles = {
  companySection: {
    alignItems: 'flex-start',
    bg: 'primary',
    'a, a dd': {
      color: 'background',
    },
  },
  sectionContent: {
    variant: 'grids.StaticSectionContent',
    h2: {
      color: 'background',
      mt: [8, 0],
    },
  },
  contentList: {
    mt: 5,
    bg: 'background',
    a: {
      variant: 'styles.a',
    },
    'h1, h2': {
      color: 'inherit !important',
    },
  },
  dataListContainer: {
    width: 'fullSize',
    flexWrap: 'wrap',

    dl: {
      mt: 8,
      display: 'grid',
      gridTemplateColumns: '2fr 3fr',
      width: 'fullSize',
      maxWidth: '500px',
      gap: 4,
      dt: {
        p: 0,
        fontSize: ['xxs', 'xs'],
      },
      dd: {
        p: 0,
        fontWeight: 'bold',
        fontSize: ['xxs', 'xs'],
      },
    },

    [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
      dl: {
        pb: 5,
        mt: 7,
        gap: 2,
        gridTemplateColumns: '1fr',
      },
      'dt:not(:first-child)': {
        mt: 5,
      },
    },

  } as SxStyleProp,
  dataList: {
  },
};
