/** @jsx jsx */
import React from 'react';
import { Grid, jsx, Styled, Flex, Box, BoxProps } from 'theme-ui';
import { Layout, StaticSEO, SectionWithContent, Title, Link, useSiteMeta, Paragraph, StrapiSectionListContainer } from '@core';
import { useTranslate } from '@modules';
import { ContactSection } from '@composite';
import { ChildrenProps } from '@types';
import { styles } from './_imprint.styles';

const DataListContainer = ({ children }: ChildrenProps) => (<Flex sx={styles.dataListContainer}>{children}</Flex>);
const DataList = ({ children }: ChildrenProps) => (<Grid as="dl" sx={styles.dataList}>{children}</Grid>);
const DataTerm = ({ children }: ChildrenProps) => (<Paragraph as="dt">{children}</Paragraph>);
const DataValue = ({ children, ...props }: ChildrenProps & Omit<BoxProps, 'ref'>) => (<Paragraph as="dd" {...props}>{children}</Paragraph>);

export default () => {
  const meta = useSiteMeta();
  const ref = React.useRef(null);
  const __ = useTranslate();

  return (
    <Layout>
      <StaticSEO pageId="imprint" />
      <SectionWithContent sx={styles.companySection}>
        <Box sx={styles.sectionContent}>
          <Title>{__('common.companyDetails')}</Title>
          <DataListContainer>
            <DataList>
              <DataTerm>{__('common.companyName')}</DataTerm>
              <DataValue>{__('pages.imprint.company.name')}</DataValue>
              <DataTerm>{__('common.companyBoss')}</DataTerm>
              <DataValue>{__('pages.imprint.company.boss')}</DataValue>
              <DataTerm>{__('common.address')}</DataTerm>
              <Link to="https://www.google.com/maps/place/Rasumofskygasse+26,+1030+Wien" title={__('common.googleMaps')} target="_blank">
                <DataValue className="adr">
                  {__('pages.imprint.company.address')}
                </DataValue>
              </Link>
              <DataTerm>{__('common.email')}</DataTerm>
              <Styled.a href={`mailto:${__('pages.imprint.company.email')}`}>
                <DataValue className="email">
                  {__('pages.imprint.company.email')}
                </DataValue>
              </Styled.a>
              <DataTerm>{__('common.website')}</DataTerm>
              <DataValue>
                <Link to={meta.siteUrl}>{meta.title}</Link>
              </DataValue>
            </DataList>

            <DataList>
              <DataTerm>{__('pages.imprint.company.ids.iban.title')}</DataTerm>
              <DataValue>{__('pages.imprint.company.ids.iban.value')}</DataValue>

              <DataTerm>{__('pages.imprint.company.ids.bic.title')}</DataTerm>
              <DataValue>{__('pages.imprint.company.ids.bic.value')}</DataValue>

              <DataTerm>{__('pages.imprint.company.ids.uid.title')}</DataTerm>
              <DataValue>{__('pages.imprint.company.ids.uid.value')}</DataValue>

              <DataTerm>{__('pages.imprint.company.ids.registry.title')}</DataTerm>
              <DataValue>{__('pages.imprint.company.ids.registry.value')}</DataValue>

              <DataTerm>{__('pages.imprint.company.ids.place.title')}</DataTerm>
              <DataValue>{__('pages.imprint.company.ids.place.value')}</DataValue>

              <DataTerm>{__('pages.imprint.company.ids.jurisdiction.title')}</DataTerm>
              <DataValue>{__('pages.imprint.company.ids.jurisdiction.value')}</DataValue>
            </DataList>
          </DataListContainer>
        </Box>
      </SectionWithContent>

      <StrapiSectionListContainer pageId="imprint" ref={ref} sx={styles.contentList} />

      <ContactSection id="landing.contact" triggerRef={() => ref} />

    </Layout>
  );
};
